import React from 'react'
import { graphql } from 'gatsby'
import { Location } from '@reach/router'
import qs from 'qs'

import PageHeader from '../components/PageHeader-small'
import PostSection from '../components/ResearchPostSection'
import PostCategoriesNav from '../components/ResearchCategoriesNav'
import Layout from '../components/Layout'

/**
 * Filter posts by date. Feature dates will be fitered
 * When used, make sure you run a cronejob each day to show schaduled content. See docs
 *
 * @param {researchPosts} object
 */
export const byDate = researchPosts => {
  const now = Date.now()
  return researchPosts.filter(researchPost => Date.parse(researchPost.date) <= now)
}

/**
 * filter posts by category.
 *
 * @param {researchPosts} object
 * @param {title} string
 * @param {contentType} string
 */
export const byCategory = (researchPosts, title, contentType) => {
  const isCategory = contentType === 'researchPostCategories'
  const byCategory = researchPost =>
    researchPost.researchCategories &&
    researchPost.researchCategories.filter(cat => cat.researchCategory === title).length
  return isCategory ? researchPosts.filter(byCategory) : researchPosts
}

// Export Template for use in CMS preview
export const ResearchIndexTemplate = ({
  title,
  subtitle,
  featuredImage,
  researchPosts = [],
  researchPostCategories = [],
  enableSearch = true,
  contentType
}) => (
  <Location>
    {({ location }) => {
      let filteredResearchPosts =
        researchPosts && !!researchPosts.length
          ? byCategory(byDate(researchPosts), title, contentType)
          : []

      let queryObj = location.search.replace('?', '')
      queryObj = qs.parse(queryObj)

      if (enableSearch && queryObj.s) {
        const searchTerm = queryObj.s.toLowerCase()
        filteredResearchPosts = filteredResearchPosts.filter(researchPost =>
          researchPost.frontmatter.title.toLowerCase().includes(searchTerm)
        )
      }

      return (
        <main className="Blog">
          <PageHeader
            title='Research Center'
            subtitle={subtitle}
            backgroundImage={featuredImage}
          />

          <div class="container space-100t" id="news">
            <h2>Explore the future of interaction</h2>
          </div>

          {!!researchPostCategories.length && (
            <section className="section thin">
              <div className="container">
                <PostCategoriesNav enableSearch categories={researchPostCategories} />
              </div>
              <div className="container space-20t space-100b">
                <PostSection researchPosts={filteredResearchPosts} />
              </div>
            </section>
          )}

        </main>
      )
    }}
  </Location>
)

// Export Default BlogIndex for front-end
const ResearchIndex = ({ data: { page, researchPosts, researchPostCategories } }) => (
  <Layout
    meta={page.frontmatter.meta || false}
    title={page.frontmatter.title || false}
  >
    <ResearchIndexTemplate
      {...page}
      {...page.fields}
      {...page.frontmatter}
      researchPosts={researchPosts.edges.map(researchPost => ({
        ...researchPost.node,
        ...researchPost.node.frontmatter,
        ...researchPost.node.fields
      }))}
      researchPostCategories={researchPostCategories.edges.map(researchPost => ({
        ...researchPost.node,
        ...researchPost.node.frontmatter,
        ...researchPost.node.fields
      }))}
    />
  </Layout>
)

export default ResearchIndex

export const pageQuery = graphql`
  ## Query for ResearchIndex data
  ## Use GraphiQL interface (http://localhost:8000/___graphql)
  ## $id is processed via gatsby-node.js
  ## query name must be unique to this file
  query ResearchIndex($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      ...Meta
      fields {
        contentType
      }
      frontmatter {
        title
        excerpt
        template
        subtitle
        featuredImage
      }
    }

    researchPosts: allMarkdownRemark(
      filter: { fields: { contentType: { eq: "researchPosts" } } }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            title
            date
            researchCategories {
              researchCategory
            }
            featuredImage
          }
        }
      }
    }
    researchPostCategories: allMarkdownRemark(
      filter: { fields: { contentType: { eq: "researchPostCategories" } } }
      sort: { order: ASC, fields: [frontmatter___title] }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
      }
    }
  }
`
